body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Arial";
}

.App {
  padding-top: 25px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.btn-custom-prev-next {
  width: 50%;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.btn-submit {
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bi {
  display: inline-block;
  vertical-align: -0.125em;
  fill: currentcolor;
  margin-right: 5px;
}

.remove-back {
  align-items: center;
  display: inline-flex;
  vertical-align: center;
}

.remove-back label {
  margin-bottom: 0px;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

.location-control {
  margin-right: 15px;
}

.dashboard-name {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.dashboard-sales-count {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* loading bar */
.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.loading-spinner {
  background: #fff; /* Background color for the spinner */
  padding: 20px;
  border-radius: 8px;
}

.pagination-area {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.total-counts {
  margin-right: 25px;
}

.error-input {
  border: 1px red solid;
}

.error-text {
  color: rgb(255, 81, 0);
}

.success-text {
  color: green;
}

.form-group {
  margin-top: 35px;
  margin-bottom: 35px;
}

.payment-icon {
  width: 150px;
  cursor: pointer;
  margin-right: 25px;
  margin-left: 25px;
}

.payicon-area {
  margin-top: 55px;
  margin-bottom: 55px;
  text-align: center;
}

.payicon-method-area {
  display: inline-flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
}

.distributor {
  display: block;
  width: 1px;
  height: 100px;
  background: blue;
}

.payment-icon.opacity {
  opacity: 0.25;
}

.navbar-area {
  position: relative;
  text-align: right;
}

.navbar-area .mobile-nav {
  display: block;
  position: absolute;
  text-align: center;
  right: 12px;
  color: wheat;
  top: 35px;
  background: rgb(105, 105, 105);
  border-radius: 5px;
}

.navbar-area .mobile-nav ul {
  padding: 0;
  margin: 0;
}

.navbar-area .mobile-nav li {
  list-style-type: none;
  margin: 0;
  padding: 25px 55px;
  border-bottom: 1px solid white;
  cursor: pointer;
}

.navbar-area .mobile-nav li:hover {
  background-color: gray;
}

.navbar-area .mobile-nav a {
  text-decoration: none;
  color: white;
}

.error-text-contact-us {
  text-align: left;
}

.welcome-intro .description {
  word-break: keep-all;
  line-height: 2;
}

section.breadcrumb-area {
  align-items: flex-end !important;
}

.welcome-page-logo {
  /* margin-top: 155px !important;
  margin-left: 55px; */
}

.btn-checkout,
.btn-submit,
.text-smallcase {
  text-transform: none !important;
}

.divider-line {
  width: 100%;
  height: 1px;
  background-color: #cfcfcf;
  margin-top: 25px;
}

.divider-line-top {
  width: 100%;
  height: 1px;
  background-color: #cfcfcf;
  margin-bottom: 25px;
}

.original-background-image {
  margin-bottom: 20px;
}

.removed-background-image {
  width: 350px;
  margin-bottom: 20px;
  height: auto !important;
}

.affiliate-program-link {
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: underline;
}

.affiliate-program-bold {
  /* font-weight: 900; */
  text-decoration: underline;
}

.affiliate-program-guide ul {
  padding-top: 10px;
}

.affiliate-program-guide li p {
  line-height: 1.65;
}

.custom-tab {
  margin-right: 20px;
}

.choose-system {
  margin-top: 55px;
}

.choose-system-title {
  margin-top: 25px;
  line-height: 2;
}

.choose-system a {
  align-items: center;
  display: flex;
}

.promotional-comment {
  margin-top: 2px !important;
  margin-bottom: 30px !important;
}

.version-link {
  color: white;
  margin-left: 55px !important;
}

.promotional-header {
  margin-top: 45px !important;
  margin-bottom: 15px !important;
}

.faq-row {
  margin-top: 35px;
  margin-bottom: 35px;
}

.dashboard-menu-item {
}

.dashboard-menus li {
  width: 450px;
  text-align: center;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 25px;
}

.payment-method {
  display: flex;
  justify-content: center;
}

.payment-method-item {
  width: 250px;
  margin: 15px;
  cursor: pointer;
}

.payment-method-item img {
  /* border: 1px solid green;
  border-radius: 15px; */
  width: 100%;
  height: 150px;
}

.payment-method-item.active img {
  border: 1px solid green;
  border-radius: 15px;
}

.dashboard-icon {
  padding: 15px;
  width: 100px !important;
  height: 100px !important;
  display: inline-flex !important;
}

.basic-modal {
  border: none !important;
  border-radius: 5px;
}

.basic-modal #modal-modal-title {
  border-bottom: 0.5px solid #1db5ff;
}

.calculator-input {
  /* width: 100px; */
  /* margin-left: 35px !important; */
  text-align: center !important;
}

.calculator-level-question {
  font-size: 20px;
  margin-top: 25px;
  /* margin-bottom: 25px; */
}

.calculator-group {
  display: inline-flex;
  align-items: center;
  margin-bottom: 55px !important;
}

.profile-ld-input {
  /* margin-top: 5px !important;
  margin-bottom: 5px !important; */
}

.img-wrap {
  position: relative;
}

.image-container {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  padding: 20px;
}

.dynamicQR-img-body {
  border: 5px solid white;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; /* Ensure it is a block element */
}

.hover-button {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 2s ease, transform 2s ease;
}

.image-container:hover .hover-button {
  display: block;
  opacity: 1;

  transform: translate(-50%, -50%) scale(1.1);
}

.hidden-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}

.visible-content {
  max-height: 1600px; /* Adjust this value based on your content */
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}

.website-link {
  margin-bottom: -20px !important;
}

.footer {
  position: relative;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 99;
}

.footer a {
  text-decoration: none;
}

@keyframes colorTransition {
  0% {
    color: red;
  }
  50% {
    color: orange;
  }
  100% {
    color: red;
  }
}

.color-transition {
  font-size: 14px;
  animation: colorTransition 1s infinite;
}

/*************** Dynamic Contact Page ****************/

:root {
  --qrc-black: #000000;
}
:root {
  --qrc-primary: #333333;
}
:root {
  --qrc-secondary: #000000;
}
:root {
  --qrc-text-primary: #333333;
}
:root {
  --qrc-text-secondary: #656b6c;
}
:root {
  --qrc-profile-primary: #ffffff;
}
:root {
  --qrc-profile-secondary: #ffffff;
}

:root {
  --qrc-card-bg: #ffffff;
}
:root {
  --qrc-border-radius: 16px;
}
:root {
  --qrc-box-shadow: 0px 7px 29px 0px #64646f33;
}
.qr_cc_card {
  background-color: var(--qrc-card-bg);
  border-radius: var(--qrc-border-radius);
  box-shadow: var(--qrc-box-shadow);
}

body {
  overflow: auto !important;
}

/* profile 3 */
.qrc_profile_3 {
  background: #fff;
  padding-bottom: 15px;
}
.qrc_profile_inner_info {
  padding: 0 15px;
  margin-top: -180px;
  position: relative;
}
.qrc_profile_3 h2 {
  font-size: 52px;
  font-weight: 400;
  margin: 20px 0 0 0;
  line-height: 54px;
}
.qrc_profile_3 p {
  font-size: 18px;
}
.qrc_profile_brand_logo img {
  max-width: 160px;
  max-height: 60px;
}

.qrc_social_icon_font {
  width: 52px;
  height: 52px;
  background-color: var(--qrc-primary);
  color: #fff;
  border-radius: 30px;
  font-size: 24px;
  padding: 10px 0 0 14px;
}
.qrc_page_wrapper {
  background-color: unset;
}
.qrc_social_icon_font {
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.qrc_contact_hdr_icon {
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.qrc_profile_3 {
  background-color: var(--qrc-secondary);
  border-radius: 18px;
  margin: 15px 0 0 0;
  border-radius: var(--qrc-border-radius);
  overflow: hidden;
  box-shadow: rgb(255 255 255 / 15%) 0px 0px 3px 0px,
    rgb(255 255 255 / 15%) 0px 8px 16px -8px;
}
.qrc_profile_3 h2 {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  color: var(--qrc-profile-primary);
  font-size: 40px;
  line-height: 46px;
  word-break: break-word;
}
.qrc_profile_3 p {
  color: var(--qrc-profile-secondary);
}
.qrc_profile_3 .qrc_profile_inner {
  padding-top: 0;
}
.qrc_profile_3 .qrc_profilepic {
  height: 464px;
  width: 100%;
  border-radius: 0;
}
.qrc_profile_3_svg {
  position: absolute;
  bottom: -5px;
}
.qrc_page_inner {
  padding-top: 0 !important;
}
.qrc_profilepic {
  position: relative;
  background-position: center;
}
.qrc_profile_shortcut ul li {
  text-align: center;
  background: var(--qrc-primary);
  color: #fff;
  padding-top: 0px;
}
.qrc_profile_shortcut ul li a:hover {
  color: #fff;
}
.qrc_profile_shortcut ul li a {
  color: #fff;
}
.qrc_gallery_list li {
  padding-top: 0px;
}
.qrc_profile_inner_info {
  margin-top: -70px !important;
}
.qrc_page_wrapper {
  background-position: top center;
  background-size: cover;
  height: unset;
  min-height: 100vh;
}

.qrc_profile_shortcut {
  margin: 15px 0 0 0;
}

.qrc_profile_3 {
  margin: 0 -15px;
  border-radius: 0px !important;
  box-shadow: unset;
}
/* @media (max-width: 767px) {
  .qrc_profile_3 {margin: 0 -15px; border-radius: 0px !important; box-shadow: unset; }
  body::-webkit-scrollbar { display: none;}
  body { -ms-overflow-style: none;  scrollbar-width: none;}
} */

.qrc_page_wrapper {
  height: 798px;
  padding-top: -15px;
}

.page_wrapper {
  width: 375px;
  height: 790px;
  -webkit-transform: scale(0.852);
  -webkit-transform-origin: 0 0;
  -ms-zoom: 0.852;
  -ms-transform-origin: 0 0;
  -moz-transform: scale(0.852);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.852);
  -o-transform-origin: 0 0;
}

.thinScrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f580;
  border-radius: 4px;
}

.thinScrollBar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
  width: 4px;
  background-color: #f5f5f580;
}

.thinScrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--theme-color-primary);
  /*background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgba(96, 143, 255, 0.7)), color-stop(0.72, rgba(96, 143, 255, 0.8)), color-stop(0.86, rgba(96, 143, 255, 0.9)));*/
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(255, 255, 255, 0.55)),
    color-stop(0.72, rgba(255, 255, 255, 0.5)),
    color-stop(0.86, rgba(255, 255, 255, 0.4))
  );
}

.preview_wrapper {
  background: #fff;
  font-size: 14px;
  color: var(--qrc-black) !important;
  margin: 0;
  padding: 0 !important;
  box-shadow: none !important;
}

.landing_page_preview_frame {
  position: relative;
  border-radius: 30px;
  border: solid 8px #021d48;
  height: 640px;
  max-width: 331px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: var(--qrc-black);
  user-select: none;
}

/* Device Header */
.device-header {
  background: #021d48;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 15px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  width: 150px;
  z-index: 99;
}

/* QR Page Preview */
.qr_page_preview {
  border-radius: 20px;
  max-height: 625px !important;
  min-height: 625px !important;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.qrp_prev_wrapper {
  width: 100%;
  margin: auto;
  min-height: 567px;
  color: #808ea7;
  max-height: 567px;
  overflow: auto;
}

.landing_page_iframe {
  border: none;
}

/* QR Code Popup */
#qrc_page_qrcode_popup {
  z-index: 1050;
}

.qrc_profile_inner_info_popup {
  text-align: center;
}

.qrc_profile_pic_popup {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 20px;
  background-size: cover;
  background-position: center;
}

.qr_popup_button_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0;
}

.qrc_action_button_icon {
  display: inline-block;
  vertical-align: middle;
}

.qrc_addtohomescreen_text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.qrc_addtohome_info img {
  margin: 10px 0;
}

/* QR Page Wrapper */

.pg_background {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-size: cover;
  background-position: top center;
}

.qrc_page_inner {
  max-width: 490px;
  margin: auto;
  position: relative;
  padding: 15px 20px 1px 15px;
  -webkit-border-top-left-radius: var(--qrc-border-radius);
  -webkit-border-top-right-radius: var(--qrc-border-radius);
  -moz-border-radius-topleft: var(--qrc-border-radius);
  -moz-border-radius-topright: var(--qrc-border-radius);
  border-top-left-radius: var(--qrc-border-radius);
  border-top-right-radius: var(--qrc-border-radius);
  padding-bottom: 80px;
  z-index: 9;
}

/* Profile Section */

.qrc_profilepic {
  width: 194px;
  height: 194px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 200px;
  margin: auto;
}

.qrc_profile_shortcut ul {
  margin: 0;
  padding: 0;
}

.qrc_profile_shortcut ul li {
  margin: 4px;
  padding: 0;
  list-style: none;
  display: inline-block;
  border-radius: 60px;
  width: 60px;
  height: 60px;
  font-size: 28px;
  color: var(--qrc-primary);
}

.qrc_profile_shortcut ul li a {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  color: var(--qrc-primary);
}

.qrc_profile_shortcut ul li a:hover {
  text-decoration: none;
  color: var(--qrc-secondary);
}

.qrc_profile_inner_info {
  padding: 0 15px;
  margin-top: -180px;
  position: relative;
}

.qrc_profile_brand_logo img {
  width: 50px;
  margin-top: 10px;
}

.qrc_profile_shortcut ul {
  display: flex;
  justify-content: left;
  padding: 0;
  list-style: none;
}

.qrc_profile_shortcut ul li {
  margin: 0 4px;
  display: inline-block;
  text-align: center;
}

/* About Me Section */
.qrc_heading_text h2 {
  color: var(--qrc-text-primary);
  font-size: 28px;
  margin: 0 0 8px 0;
  font-weight: 700;
}

.qrc_heading_text {
  text-align: center;
  padding: 15px 20px;
  margin: 0 0 15px 0;
}

.qrc_heading_text p {
  font-size: 20px;
  color: var(--qrc-text-secondary);
  margin: 0;
  word-break: break-word;
}

/* Contact Section */
.qrc_contact {
  text-align: left;
  padding: 15px;
  margin: 15px 0;
}

.qrc_contact_header {
  display: flex;
  align-items: center;
  border-bottom: dashed 1px #ecedf7;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.qrc_contact_hdr_img {
  width: 52px;
  height: 52px;
  background-size: cover;
  border-radius: 50px;
  flex-shrink: 0;
}

.qrc_contact_hdr_icon {
  width: 52px;
  height: 52px;
  background-color: var(--qrc-primary);
  color: var(--qrc-card-bg);
  border-radius: 30px;
  font-size: 28px;
  padding: 8px 0 0 12px;
}

.qrc_contact_hdr_icon span {
}

.qrc_contact_hdr_text {
  font-size: 20px;
  margin-left: 15px;
  color: var(--qrc-text-primary);
}

.qrc_contact_info_title {
  font-size: 20px;
  color: var(--qrc-text-primary);
}

.qrc_contact_info {
  margin-bottom: 20px;
}

.main a:hover,
.review-container:hover {
  background-color: #0000000a;
  cursor: pointer;
}

.qrc_contact_number,
.qrc_email_id,
.qrc_address_text {
  color: var(--qrc-text-secondary);
}

.qrc_contact_number a,
.qrc_email_id a {
  color: var(--qrc-text-secondary);
  text-decoration: none;
}

.qrc_email_info_title {
  font-size: 20px;
  color: var(--qrc-text-primary);
}

.qrc_email_info {
  margin-bottom: 20px;
}

.qrc_address_info {
  margin-bottom: 20px;
}

.qrc_address_info_title {
  font-size: 20px;
  color: var(--qrc-text-primary);
}

.qrc_direction_btn {
  background-color: var(--qrc-primary);
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
}

.qrc_direction_btn span {
  margin-right: 0.325rem;
}

.qrc_direction_btn:hover {
  text-decoration: none;
  color: #fff;
}

.qrc_caption {
  padding: 10px 5px;
  text-align: center;
}

.qrc_caption h6 {
  color: #fff;
}

/* calltoaction (button) */
.qrc_calltoaction {
  padding: 15px;
  margin: 15px 0;
}

.qrc_button {
  background-color: var(--qrc-primary);
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  text-align: center;
  display: block;
  font-size: 20px;
}

.qrc_button:hover {
  color: var(--qrc-text-secondary);
}

/* Gallery Section */
.qrc_gallery {
  padding: 0 15px 15px 15px;
  margin: 15px 0;
  overflow: hidden;
}

.qrc_gallery_wrapper {
  margin: 0px -15px -20px -15px;
}

.qrc_gallery_wrapper .swiper {
  width: 100%;
  height: 100%;
}

.qrc_gallery_wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 375px !important;
}

.qrc_gallery_wrapper .swiper-pagination-bullet {
  background-color: white !important;
  opacity: 1;
}

.qrc_gallery_wrapper .swiper-pagination-bullet-active {
  background-color: black !important;
}

.qrc_gallery_wrapper .slide {
  height: 375px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.qrc_gallery_wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qrc_gallery .link[href="#"] {
  pointer-events: none !important;
  cursor: default !important;
}

.qrc_gallery .image_gallery_title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #061244;
  margin-top: 5px;
  margin-bottom: 30px;
}

.qrc_gallery .image_gallery_title:empty {
  display: none;
}

.qrc_gallery .qrc_heading {
  border-bottom: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.qrc_gallery_list {
  margin: 0;
  padding: 0;
  border-radius: var(--qrc-border-radius);
  overflow: hidden;
}

.qrc_gallery_list li {
  margin: 0 0 8px 0;
  padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
  width: 100%;
  /* 4:5 Aspect Ratio */
  background-size: cover;
}

.qrc_gallery_list li:last-child {
  margin: 0 0 0px 0;
  -webkit-border-bottom-right-radius: var(--qrc-border-radius);
  -webkit-border-bottom-left-radius: var(--qrc-border-radius);
  -moz-border-radius-bottomright: var(--qrc-border-radius);
  -moz-border-radius-bottomleft: var(--qrc-border-radius);
  border-bottom-right-radius: var(--qrc-border-radius);
  border-bottom-left-radius: var(--qrc-border-radius);
}

.qrc_gallery_list li img {
  width: 100%;
}

.qrc_gallery_grid_1 {
  margin: 0;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
}

.qrc_gallery_grid_1 li {
  margin: 0 0 8px 0;
  padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  /* 4:5 Aspect Ratio */
  background-size: cover;
}

.qrc_gallery_grid_1 li:nth-child(3n + 2) {
  width: calc(50% - 0px);
}

.qrc_gallery_grid_1 li:nth-child(3n + 3) {
  width: calc(50% - 13px);
  margin-left: 8px;
}

.qrc_gallery_grid_1 li img {
  width: 100%;
}

/* .qrc_gallery_grid_1 li:last-child{width:100%;   margin: 0 0 0px 0;-webkit-border-bottom-right-radius: var(--qrc-border-radius); -webkit-border-bottom-left-radius: var(--qrc-border-radius); -moz-border-radius-bottomright: var(--qrc-border-radius); -moz-border-radius-bottomleft: var(--qrc-border-radius); border-bottom-right-radius: var(--qrc-border-radius); border-bottom-left-radius: var(--qrc-border-radius); } */

.qrc_gallery_grid_2 {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  border-radius: 12px;
  overflow: hidden;
}

.qrc_gallery_grid_2 li {
  margin: 0 0 8px 0;
  display: flex;
  /* padding: 0;
  list-style: none;
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  background-size: cover; */
}

.qrc_gallery_grid_2 li:nth-child(odd) {
  width: calc(50% - 4px);
}

.qrc_gallery_grid_2 li:nth-child(even) {
  width: calc(50% - 4px);
  margin-left: 8px;
}

.qrc_gallery_grid_2 li img {
  width: 100%;
}

.qrc_gallery_grid_2 li a {
  display: flex;
  margin: auto;
}

.qrc_gallery_grid_2 li:last-child {
  margin-bottom: 5px !important;
}

/* :nth-last-child(2){margin-bottom: 5px !important;} */

/* .qrc_gallery_grid_2 li:last-child{width:100%;   margin: 0 0 0px 0;-webkit-border-bottom-right-radius: var(--qrc-border-radius); -webkit-border-bottom-left-radius: var(--qrc-border-radius); -moz-border-radius-bottomright: var(--qrc-border-radius); -moz-border-radius-bottomleft: var(--qrc-border-radius); border-bottom-right-radius: var(--qrc-border-radius); border-bottom-left-radius: var(--qrc-border-radius); } */

/* Social Links Section */
.qrc_heading p {
  font-size: 20px;
  color: var(--qrc-text-secondary);
  margin: 0;
}

.qrc_heading {
  text-align: center;
  border-bottom: dashed 1px #ecedf7;
  margin-bottom: 15px;
  padding: 15px 0;
}

.no_data .qrc_heading {
  margin-bottom: 0px;
  border-bottom: none;
}

.qrc_heading h2 {
  color: var(--qrc-text-primary);
  font-size: 28px;
  margin: 0 0 8px 0;
}
.qrc_logo {
  width: 200px;
  max-height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  overflow: hidden;
}
.qrc_logo img {
  max-width: 200px;
  max-height: 200px;
}

.form_container {
  padding: 1em;
  border: 1px solid var(--light-color);
  border-radius: 4px;
}

.qrc_form_container {
  border-top: 2px dashed var(--light-color);
  padding-top: 1rem;
}

.qrc_form_container .qrc_terms label {
  margin-bottom: 1px;
}
.qrc_form_container .qrc_terms input {
  margin-right: 0.5em;
}
.qrc_form_container .qrc_terms {
  margin-top: 1em;
  display: flex;
  align-items: center;
}
.qrc_form_container .qrc_button_transparent {
  color: var(--qrc-secondary);
  padding: 8px 20px;
  border-radius: 25px;
  text-align: center;
  display: block;
  font-size: 16px;
}
.qrc_form_container .qrc_button {
  margin-top: 1em;
}
.qrc_form_container .invalid_field .form-control {
  border-color: #f00;
}
.qrc_form_container .qrc_terms.invalid_field input {
  outline: 2px solid #f00;
  outline-style: auto;
}
.qrc_form_container .form-control {
  border-color: var(--light-color);
  border-style: groove;
  border-radius: 4px;
  width: 100%;
}

.qrc_forms .qrc_heading h2 {
  font-size: 24px !important;
}
.qrc_forms .qrc_heading p {
  font-size: 18px !important;
  font-weight: 600;
}

.qrc_form_container .qrc_button:hover {
  color: #fff;
}

.qrc_social_links_list {
  margin: 0;
  padding: 0;
}

.qrc_social_links_list li {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 15px;
}

.qrc_social_links_list li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.qrc_social_links_list li a:hover {
  text-decoration: underline;
}

.qrc_social_links {
  background-color: var(--qrc-card-bg);
  border-radius: var(--qrc-border-radius);
  box-shadow: var(--qrc-box-shadow);
}

.qrc_social_icon {
  /* width: 52px;
  height: 52px;
  background-size: cover;
  border-radius: 50px; */
}

.qrc_social_text {
  width: calc(100% - 110px);
  margin: 0 15px;
  text-align: left;
  line-break: anywhere;
}

.qrc_social_text_heading {
  font-size: 20px;
  color: var(--qrc-text-primary);
  overflow-wrap: break-word;
}

.qrc_social_text_discription {
  font-size: 14px;
  color: var(--qrc-text-secondary);
}

.qrc_social_action {
  font-size: 20px;
  color: var(--qrc-primary);
}

.extra_button_wrapper {
  position: fixed;
  bottom: 0;
}

.extra_button_wrapper {
  /* position: fixed; */
  max-width: 300px;
  width: 100%;
  bottom: calc(100vh - 670px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.extra_button_wrapper button {
  width: 60px;
  height: 60px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  background: var(--qrc-primary);
  border-radius: 52px;
  border: solid 1px #ffffff60;
  color: #fff;
  font-size: 24px;
  justify-content: center;
}

/* addtocontact */
.qrc_addtocontact {
  display: flex;
  align-items: center;
  z-index: 9;
  background: var(--qrc-primary);
  padding: 8px 8px 8px 12px;
  margin-right: 5px;
  border-radius: 52px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 2px #ffffff20;
  justify-content: space-between;
  width: 150px;
  cursor: pointer;
}

.qrc_addtocontact_disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.qrc_addtocontact_text {
  color: #fff;
  width: 72px;
  text-align: center;
}

.qrc_addtocontact_circle {
  background-color: #fff;
  color: var(--qrc-primary);
  width: 42px;
  height: 42px;
  border-radius: 42px;
  margin-left: 15px;
  font-size: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.qr_page_loader {
  display: flex;
  justify-content: center;
  height: 640px;
  align-items: center;
}

.preview_wrapper a:hover,
.preview_wrapper a:focus {
  color: inherit;
}

.loader_img {
  width: 100px;
  height: 100px;
  /* max-width: 80%;
  max-height: 60%; */
}

.qr-code-purchase-note-text {
  text-align: left;
  word-break: keep-all;
  font-size: 18px;
  line-height: 30px;
}

.checkout .extra_button_wrapper {
  left: calc(50% + 385px);
  bottom: calc(100vh - 925px);
  display: none;
}

.checkout .qrc_page_inner {
  padding-bottom: 10px;
}

.preview-caption {
  text-align: center;
  padding-bottom: 10px;
}

.dashboard .extra_button_wrapper {
  left: calc(50% + 385px);
  bottom: calc(100vh - 975px);
}

.qr-dialog {
  position: absolute !important;
  top: calc(350px);
  transform: translateY(-50%);
}

.dashboard.qr-dialog {
  left: calc(50% + 355px);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
  .dashboard .extra_button_wrapper {
    left: 50% !important;
    bottom: calc(100vh - 920px);
  }

  .dashboard.qr-dialog {
    position: absolute !important;
    top: calc(320px);
    transform: translateX(-50%);
  }
}

/**********   FAQ   **********/

.faq-container {
  display: flex;
  padding: 20px;
}

.image-container {
  flex: 1;
}

.faq-image {
  width: 100%;
  height: auto;
}

.faq-content {
  flex: 1;
  margin-left: 20px;
}

.faq-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.faq-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.faq-content a {
  color: #1e90ff;
  text-decoration: none;
}

.faq-content a:hover {
  text-decoration: underline;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  background-color: #444;
  border: none;
  border-radius: 5px;
  margin: 0;
}

.faq-answer {
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
}

body {
  padding-right: 0px !important;
}

.content {
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
}
.content h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}
.content ol {
  padding-left: 20px;
  list-style-type: decimal; /* Ensure indices are displayed */
}
.content ol li {
  margin-bottom: 20px;
  line-height: 1.6;
  list-style: decimal;
}
.content ol li b,
.content ol li::marker {
  font-size: 1.1em;
}
.content p {
  margin-top: 20px;
  color: rgb(0, 0, 0, 0.87);
}

.downstream-users-grid {
  padding-left: 20px;
}

.downstream-users-grid p {
  text-align: center;
  margin: 20px;
}

.downstream-users-grid .downstream-users {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}

.downstream-users-grid table {
  margin-top: 24px;
}

.downstream-users-grid .activity-count {
  text-align: center;
}

.downstream-users-grid .activity-count span {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

table .event-content {
  text-align: center;
}

.level-select {
  text-align: right;
  display: inline-flex;
  align-items: center;
}
.level-select.admin-dashboard-downstream {
  display: block;
}
.levels-summary {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  text-wrap: balance;
}

.downstream-users-grid table td,
.downstream-users-grid table th {
  border: 1px solid gray;
}

.downstream-users-grid table th {
  font-weight: 1000;
}

.store-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
  background-color: #f5f5f5;
}

.product-card {
  width: 500px;
  background-color: white;
  border-radius: 10% / 10%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.product-image {
  width: 60%;
  border-radius: 10px;
}

.admin--product-image {
  width: 50px;
  border-radius: 10px;
}

.product-title {
  font-size: 1.5em;
  margin: 15px 0;
}

.store-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 15px 0;
}

.product-description,
.product-info {
  font-size: 1rem;
  color: #555;
  margin: 15px 0;
}

.product-price {
  font-size: 1.2rem;
  margin: 10px 0;
  font-weight: bold;
}

.purchase-button {
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
}

.purchase-button:hover {
  background-color: #0b78e6;
}

p.description {
  padding: 15px 5px;
}

.checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.checkout .qr-image {
  width: 100%;
  display: block;
  margin: auto;
}
.checkout .label-text-notify {
  margin: 30px 0;
  text-align: center;
}
.checkout button {
  margin: 0 auto;
}
.button-text {
  text-align: center;
}
.paper {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.btn-custom-prev-next {
  background-color: #1976d2;
  color: white;
  text-transform: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
}

.btn-custom-prev-next:hover {
  background-color: #115293;
}

.website-link-gray {
  position: relative;
  background-color: #e5e5e5;
}

.website-link-gray::before {
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  width: 20px;
  bottom: 0;
  background-color: #e5e5e5;
}

.website-link-gray::after {
  content: "";
  position: absolute;
  top: 0;
  right: -20px;
  width: 20px;
  bottom: 0;
  background-color: #e5e5e5;
}

.web-link-title,
.web-link-url {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.website-link-gray .web-link-title {
  background-color: white;
}

.website-link-gray .web-link-url {
  background-color: white;
}

.web-link-label {
  margin-bottom: -10px;
}

#qrlabel-area {
  position: relative;
  display: inline-block;
}

#qrlabel-area .qr-preview-img {
  margin-top: 5px;
}

#qrlabel-area .qrlabel-preview-text {
  margin: 0px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  font-size: 23px;
  text-wrap: nowrap;
}

.share-effortless-div {
  align-items: center;
  justify-content: space-around;
}

.share-effortless-div .share-left-image {
  max-height: 500px;
  margin-right: 200px;
}

.share-effortless-div .share-right-image {
  max-height: 500px;
}

.share-effortless-div .share-comission-image {
  max-height: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ezstore-section p {
  text-align: left;
  word-wrap: break-word;
}

.slick-dots li { 
  width: 8px !important;
  margin: 0 5px !important;
}

.qr_condo_card {
  padding: 23px;
}

.rdrDefinedRangesWrapper{
  display: none;
}

.rdrDayNumber span {
  color: black !important;
  font-size: 14px;
}

.rdrDay.rdrDayDisabled span {
  /* color: #ffffff !important; */
  text-decoration: line-through !important;
}

.rdrDay {
  /* background-color: #96e040 !important; */
}

.rdrDay.rdrDayDisabled {
  /* background-color: gray !important; */
}

.rdrDateInput input{
  color: black !important;
}

.calendar-container {
  touch-action: none; 
}

@media (max-width: 768px) {
  .calendar-container {
    /* width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: white;
    overflow: hidden; */
  }

  .rdrCalendarWrapper {
    width: 100% !important;
  }

  .rdrMonth {
    width: 100% !important;
    padding: 0 !important;
  }

  .rdrDayNumber span {
    font-size: 14px;
  }

  .rdrDay {
    height: 38px !important;
  }

  .rdrMonthAndYearWrapper, 
  .rdrNextPrevButton {
    font-size: 12px;
    padding: 5px;
  }
  
  .rdrMonths {
    width: 100% !important;
  }

  /* For Guests Dropdown */
  select {
    width: 100%;
    font-size: 16px;
  }

  .calendar-container .flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .calendar-container .flex > div {
    width: 100%;
  }
}

.rdrDayPassive {
  visibility: hidden;
  pointer-events: none;
}

/* Scrollbar for Firefox */
.modal-content {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1);
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


.scrollbar-custom {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1); /* For Firefox */
}

.scrollbar-custom::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Light gray background for track */
  border-radius: 10px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4); /* Darker scrollbar thumb */
  border-radius: 10px; /* Rounded scrollbar */
  border: 2px solid rgba(0, 0, 0, 0.1); /* Adds some spacing */
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6); /* Darker on hover */
}

/* Specific Application */
.modal-content {
  max-height: 100vh;
  overflow-y: auto; /* Ensure modal content can scroll */
  /* Apply the scrollbar style */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1); /* For Firefox */
}

.modal-content::-webkit-scrollbar {
  width: 12px;
}

.modal-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Light gray background for track */
  border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4); /* Darker scrollbar thumb */
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1); /* Adds some spacing */
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6); /* Darker on hover */
}

.reviews-scroll {
  max-height: 500px; /* Specific height for the reviews section */
  overflow-y: auto; /* Ensure reviews section can scroll */
  /* Apply the scrollbar style */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1);
}

.reviews-scroll::-webkit-scrollbar {
  width: 12px;
}

.reviews-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.reviews-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.reviews-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.custom-scrollbar {
  /* Inherit same scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
}


html, body {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1) !important; /* Firefox */
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 12px !important;
}

html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px !important;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
}

html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6) !important;
}